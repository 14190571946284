import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store'
import { showCalendarEventModal, showConfirmModal, showDealModal, showDealStageModal } from '../store/modals/actions'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ActivityTrackableType, ActivityType, CurrentUser, Deal, DealStage as DealStageType, DealStatus, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../types';
import TopNavigation from '../components/Navigation/TopNavigation';
import PageHeader from '../components/Page/PageHeader';
import Icon from '../components/Icons/Icon';
import { ActivitiesController, DealStagesController, DealsController } from '../controllers';
import ButtonGroup from '../components/Button/ButtonGroup';
import PageContent from '../components/Page/PageContent';
import { DragDropContext, DragStart, DragUpdate, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import DroppableHelper from '../helpers/DroppableHelper';
import DealHelper from '../helpers/DealHelper';
import DealsContainer from '../components/Deals/DealsContainer';
import DealStage from '../components/Deals/DealStage';
import DealsWrapper from '../components/Deals/DealsWrapper';
import ActionCableConsumer from '../consumers/ActionCableConsumer';
import { createDeal, createDealStage, deleteDeal, deleteDealStage, setInitialDealsState, updateDeal, updateDealStage } from '../store/deals/actions';
import { DealsBoardState } from '../store/deals/types';
import PowerSelect from '../components/Form/PowerSelect';
import DealSidebarFilter, { DealStatusFilter, IDealBoardFilter } from '../components/Deals/DealSidebarFilter';
import ButtonFilter from '../components/Button/ButtonFilter'
import ReactTooltip from 'react-tooltip'
import ERoute from '../ERoute'
import RouteHelper from '../helpers/RouteHelper'
import PageHeaderNavigation from '../components/Page/PageHeaderNavigation'
import PageHeaderNavigationLeft from '../components/Page/PageHeaderNavigationLeft'
import PageHeaderNavigationRight from '../components/Page/PageHeaderNavigationRight'
import DealsTable from '../components/Deals/DealsTable'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import Popover from '../components/Popover/Popover'
import MoveDealsPopover from '../components/Deals/MoveDealsPopover'
import LostDealsPopover from '../components/Deals/LostDealsPopover'

const AddStageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
	background-color: #ebecf0;
	border-radius: 3px;
	min-height: 32px;
	transition: background 85ms ease-in,opacity 40ms ease-in,border-color 85ms ease-in;
	margin-right: 8px;
	width: 272px;
	min-width: 272px;
	padding: ${Style.spacing.x0_5} ${Style.spacing.x3};
  height: 100%;
  flex-direction: column;
  border: 1px solid ${Style.color.border};
  opacity: .3;
  transition: opacity 85ms ease-in;

	&:first-child {
		margin-left: ${Style.spacing.x1};
	}

	input {
		height: 36px;
    min-height: 36px;
		margin-bottom: ${Style.spacing.x0_5};
	}

	&:hover {
    color: #172b4d;
    opacity: 1;
  }
`

const AddStageTitle = styled.div`
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 600;
`
const AddStageDescription = styled.div`
  text-align: center;
  margin-bottom: ${Style.spacing.x2};
`

const AddStageAction = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px 8px;
	transition: color 85ms ease-in;
	cursor: pointer;
  background: #fff;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};

  &:hover {
    background: #f4f5f7;
  }

	svg, i {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		color: #42526e;
		margin-right: 4px;
	}
`

const DealActionsContainer = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 20;
  user-select: none;
  transition: all 0.2s ease-in-out;
  bottom: -100px;
  pointer-events: none;

  ${props => props.show && css`
    bottom: 0;
    pointer-events: all;
  `}

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
  }
`

const DealActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  grid-gap: ${Style.spacing.x1};
  padding: 8px 16px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px 0px, rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.05) 0px 3px 4px 0px, rgba(0, 0, 0, 0.16) 0px 0px 2px 0px;
`

const DealAction = styled.div<{ color?: string, isHovering?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Style.variables.baseBorderRadius};
  box-sizing: border-box;
  border: 4px dashed rgb(215, 215, 217);
  background: initial;
  color: rgb(101, 104, 111);
  height: 40px;
  text-transform: uppercase;
  font-weight: bold;

  ${props => props.color && css`
    color: ${props.color};
  `}

  &:hover {
    background: #d7d7d9;

    ${props => props.color && css`
      background: ${props.color};
      color: white;
      border-color: ${props.color};
    `}
  }
`

export const enum DealDroppableType {
  BOARD = 'board',
  ACTION = 'action'
}

export enum DealsLayout {
  TABLE = 'table',
  BOARD = 'board'
}

export enum DealsAction {
  DELETE = 'delete',
  LOST = 'lost',
  WON = 'won',
  MOVE = 'move'
}

interface IStateToProps {
  currentUser: CurrentUser
  dealsBoard: DealsBoardState
}

interface IDispatchToProps {
  showDealModal: typeof showDealModal
  showConfirmModal: typeof showConfirmModal
  setInitialDealsState: typeof setInitialDealsState
  createDeal: typeof createDeal
  updateDeal: typeof updateDeal
  deleteDeal: typeof deleteDeal
  createDealStage: typeof createDealStage
  updateDealStage: typeof updateDealStage
  deleteDealStage: typeof deleteDealStage
  showCalendarEventModal: typeof showCalendarEventModal
  showDealStageModal: typeof showDealStageModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  activeLayout: DealsLayout
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  boardFilterActive: boolean
  boardFilter: IDealBoardFilter
  movePopoverActive: boolean
  lostDealsPopoverActive: boolean
  dragAction: string
  actionsActive: boolean
  selectedDealIds: string[]
}

class Deals extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      activeLayout: DealsLayout.BOARD,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.DEAL_SORT_VALUE, '-'),
      boardFilterActive: false,
      boardFilter: {
        searchValue: '',
        status: DealStatusFilter.OPEN,
        dueState: null,
      },
      lostDealsPopoverActive: false,
      movePopoverActive: false,
      dragAction: null,
      actionsActive: false,
      selectedDealIds: []
    }

    this.onNewDealClick = this.onNewDealClick.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this)
    this.onActionCableConnected = this.onActionCableConnected.bind(this)
    this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
    this.onActionCableReceived = this.onActionCableReceived.bind(this)
    this.onBeforeDragStart = this.onBeforeDragStart.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.reorderDealStage = this.reorderDealStage.bind(this)
    this.reorderDeal = this.reorderDeal.bind(this)
    this.onDealClick = this.onDealClick.bind(this)
    this.onAddDealClick = this.onAddDealClick.bind(this)
    this.onChangeDealStageName = this.onChangeDealStageName.bind(this)
    this.onMoveDealsClick = this.onMoveDealsClick.bind(this)
    this.onDeleteDealsClick = this.onDeleteDealsClick.bind(this)
    this.onEditDealStageClick = this.onEditDealStageClick.bind(this)
    this.onDeleteDealStageClick = this.onDeleteDealStageClick.bind(this)
    this.onBoardFilterChange = this.onBoardFilterChange.bind(this)
    this.getActiveBoardFilterCount = this.getActiveBoardFilterCount.bind(this)
    this.onBoardFilterSearchChange = this.onBoardFilterSearchChange.bind(this)
    this.onBoardFilterStatusChange = this.onBoardFilterStatusChange.bind(this)
    this.onBoardFilterToggle = this.onBoardFilterToggle.bind(this)
    this.onBoardFilterClose = this.onBoardFilterClose.bind(this)
    this.onAddDealStageClick = this.onAddDealStageClick.bind(this)
    this.onAddDealStageSubmit = this.onAddDealStageSubmit.bind(this)
    this.onActionDeleteDrop = this.onActionDeleteDrop.bind(this)
    this.onActionWonDrop = this.onActionWonDrop.bind(this)
    this.onActionLostDrop = this.onActionLostDrop.bind(this)
    this.onLostDealsPopoverSubmit = this.onLostDealsPopoverSubmit.bind(this)
    this.onLostDealsPopoverClose = this.onLostDealsPopoverClose.bind(this)
    this.onActionMoveDrop = this.onActionMoveDrop.bind(this)
    this.onMoveDealsPopoverSubmit = this.onMoveDealsPopoverSubmit.bind(this)
    this.onMoveDealsPopoverClose = this.onMoveDealsPopoverClose.bind(this)
    this.onActionsCloseClick = this.onActionsCloseClick.bind(this)
    this.onDealActionMouseOver = this.onDealActionMouseOver.bind(this)
    this.onDealActionMouseOut = this.onDealActionMouseOut.bind(this)
  }

  componentWillMount() {
    this.fetchBoard()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  componentWillUnmount(): void {
    this.props.setInitialDealsState({ deals: [], dealStages: [] })
  }

  async fetchBoard() {
    try {
      const { deals, deal_stages } = await DealsController.getBoard()

      this.props.setInitialDealsState({ deals: [...deals], dealStages: [...deal_stages] })
      this.setState({ didInitialLoad: true, isFetching: false });
    } catch (ex) {
      console.error(ex)
    }
  }

  onActionCableConnected() {
    console.log('[WorkspaceChannel] connected')
  }

  onActionCableDisconnected() {
    console.log('[WorkspaceChannel] disconnected')
  }

  onActionCableReceived(event: WorkspaceChannelEvent) {
    console.log('[WorkspaceChannel] event received', event)

    switch (event.type) {
      case WorkspaceChannelEventType.DEAL_CREATE:
        this.props.createDeal(event.data.deal)
        break
      case WorkspaceChannelEventType.DEAL_UPDATE:
        this.props.updateDeal(event.data.deal)
        break
      case WorkspaceChannelEventType.DEAL_DELETE:
        this.props.deleteDeal(event.data.deal_id)
        break
      case WorkspaceChannelEventType.DEAL_STAGE_CREATE:
        this.props.createDealStage(event.data.deal_stage)
        break
      case WorkspaceChannelEventType.DEAL_STAGE_UPDATE:
        this.props.updateDealStage(event.data.deal_stage)
        break
      case WorkspaceChannelEventType.DEAL_STAGE_DELETE:
        this.props.deleteDealStage(event.data.deal_stage_id)
        break
    }
  }

  onNewDealClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    const { showDealModal } = this.props

    showDealModal({ deal: {} })
  }

  onLayoutChange(layout: DealsLayout) {
    this.setState({ activeLayout: layout, didInitialLoad: false }, () => {
      this.setState({
        boardFilterActive: false,
        boardFilter: {
          searchValue: '',
          status: DealStatusFilter.ALL,
          dueState: null,
        },
        didInitialLoad: layout === DealsLayout.TABLE
      })
      this.props.setInitialDealsState({ deals: [], dealStages: [] })

      if (layout === DealsLayout.BOARD) this.fetchBoard()
    })
  }

  onDealClick(deal: Deal) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_DEAL, { id: deal.id }))
  }

  onAddDealClick(dealStage: DealStageType, position: number) {
    this.props.showDealModal({
      deal: {
        deal_stage_id: dealStage.id,
        position: position
      }
    })
  }

  async onChangeDealStageName(dealStageId: string, name: string) {
    const { dealsBoard: { dealStages } } = this.props

    try {
      const dealStageIndex = dealStages.findIndex(l => l.id === dealStageId)

      if (dealStageIndex !== -1) {
        // Set new name
        dealStages[dealStageIndex].name = name

        // Set local cache
        this.props.updateDealStage(dealStages[dealStageIndex])

        // Update remote
        await DealStagesController.update(dealStages[dealStageIndex])
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onMoveDealsClick(sourceList: DealStageType, destinationList: DealStageType) {
    const { dealsBoard: { deals } } = this.props

    try {
      const dealIds = deals
        .filter(deal => deal.deal_stage_id === sourceList.id)
        .map(deal => deal.id)

      const response = await DealsController.bulkMove(dealIds, destinationList.id)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDeleteDealsClick(dealStage: DealStageType) {
    const { dealsBoard: { deals }, t } = this.props

    this.props.showConfirmModal({
      title: t('Deals::Delete all deals from this list'),
      description: t('Deals::You are about to delete all deals from this list. These deals will be permanently deleted along with all the associated data. Are you sure?'),
      action: { label: t('Deals::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const dealIds = deals
            .filter(deal => deal.deal_stage_id === dealStage.id)
            .map(deal => deal.id)

          await DealsController.deleteAll(dealIds)
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  async onEditDealStageClick(dealStage: DealStageType) {
    this.props.showDealStageModal({
      dealStage: { id: dealStage.id },
      onSubmit: (dealStage) => {
        this.props.updateDealStage(dealStage)
      }
    })
  }

  async onDeleteDealStageClick(dealStage: DealStageType) {
    const { showConfirmModal, t } = this.props
    const { dealsBoard: { dealStages } } = this.props

    requestAnimationFrame(() => {
      showConfirmModal({
        title: t('Deals::Delete deal stage'),
        description: t('Deals::You are about to delete this deal stage. This deal stage will be permanently deleted along with all it\'s associated data. Are you sure?'),
        action: { label: t('Deals::Delete'), isDestructive: true },
        onConfirm: async () => {
          try {
            const dealStageIndex = dealStages.findIndex(l => l.id === dealStage.id)

            if (dealStageIndex !== -1) {
              // Remove list from lists
              dealStages.splice(dealStageIndex, 1);

              // Set local cache
              this.props.deleteDealStage(dealStage.id)

              // Update remote
              await DealStagesController.delete(dealStage.id)
            }
          } catch (ex) {
            console.error(ex)
          }
        }
      })
    })
  }

  onAddDealStageClick() {
    const { dealsBoard: { dealStages } } = this.props


    this.props.showDealStageModal({
      dealStage: {
        position: DealHelper.getCreatePosition(dealStages)
      },
      onSubmit: (dealStage) => {
        this.props.createDealStage(dealStage)
      }
    })
  }

  async onAddDealStageSubmit(name: string) {
    const { dealsBoard: { dealStages, } } = this.props

    try {
    } catch (ex) {
      console.error(ex)
    }
  }

  async onActionDeleteDrop(dealId: string) {
    const { deleteDeal, t } = this.props
    const { selectedDealIds } = this.state

    requestAnimationFrame(() => {
      this.props.showConfirmModal({
        title: t('Deals::Delete deal'),
        description: t('Deals::You are about to delete this deal. This deal will be permanently deleted along with all the associated data. Are you sure?'),
        action: {
          label: t('Deals::Delete'),
          isDestructive: true
        },
        onConfirm: async () => {
          // Delete selected deals
          selectedDealIds.forEach(dealId => deleteDeal(dealId))

          // Reset actions
          this.setState({ actionsActive: false, selectedDealIds: [] })

          try {
            // Update remote
            await DealsController.deleteAll(selectedDealIds)
          } catch (ex) {
            console.error(ex)
          }
        },
        onCancel: () => {
          this.setState({ actionsActive: false, selectedDealIds: [] })
        }
      })
    })
  }

  onActionWonDrop(dealId: string) {
    const { t, dealsBoard: { dealStages } } = this.props
    const { selectedDealIds } = this.state

    // Reset selected deals
    this.setState({
      movePopoverActive: false,
      lostDealsPopoverActive: false,
    })

    requestAnimationFrame(() => {
      this.props.showConfirmModal({
        title: t('Deals::Mark as won'),
        description: t('Deals::You are about to mark the selected deal as won. Are you sure?'),
        action: {
          label: t('Deals::Yes'),
        },
        onConfirm: async () => {
          try {
            const dealStage = DealHelper.getDealStageForStatus(dealStages, DealStatus.WON)

            if (dealStage) {
              selectedDealIds.forEach(async (dealId) => {
                const deal = await DealsController.update({ id: dealId, deal_stage_id: dealStage.id })
                this.props.updateDeal(deal)
              })
            } else {
              selectedDealIds.forEach(async (dealId) => {
                const deal = await DealsController.update({ id: dealId, status: DealStatus.WON })
                this.props.updateDeal(deal)
              })
            }
          } catch (ex) {
            console.error(ex)
          }
        },
        onCancel: () => {
          this.setState({ actionsActive: false, selectedDealIds: [] })
        }
      })
    })
  }

  onActionLostDrop(dealId: string) {
    this.setState({
      lostDealsPopoverActive: true,
      actionsActive: true,
      selectedDealIds: [dealId],
    })
  }

  onLostDealsPopoverSubmit(lostReason: string, note?: string) {
    const { dealsBoard: { dealStages } } = this.props
    const { selectedDealIds } = this.state

    this.setState({
      movePopoverActive: false,
      lostDealsPopoverActive: false,
      actionsActive: false,
      selectedDealIds: []
    })

    try {
      const dealStage = DealHelper.getDealStageForStatus(dealStages, DealStatus.LOST)

      if (dealStage) {
        selectedDealIds.forEach(async (dealId) => {
          const deal = await DealsController.update({
            id: dealId,
            deal_stage_id: dealStage.id,
            lost_reason: lostReason
          })
          this.props.updateDeal(deal)
        })
      } else {
        selectedDealIds.forEach(async (dealId) => {
          const deal = await DealsController.update({
            id: dealId,
            status: DealStatus.LOST,
            lost_reason: lostReason
          })
          this.props.updateDeal(deal)
        })
      }

      if (note) {
        selectedDealIds.forEach(async (dealId) => {
          await ActivitiesController.create({
            type: ActivityType.NOTE,
            trackable_type: ActivityTrackableType.DEAL,
            trackable_id: dealId,
            owner_type: 'User',
            owner_id: this.props.currentUser.id,
            data: { note: note }
          })
        })
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onLostDealsPopoverClose() {
    this.setState({
      lostDealsPopoverActive: false,
      selectedDealIds: [],
      actionsActive: false
    })
  }

  async onActionMoveDrop(dealId: string) {
    this.setState({
      movePopoverActive: true,
      actionsActive: true,
      selectedDealIds: [dealId]
    })
  }

  onMoveDealsPopoverSubmit(dealStage: DealStageType) {
    const { selectedDealIds } = this.state

    selectedDealIds.forEach(async (dealId) => {

      const deal = await DealsController.update({ id: dealId, deal_stage_id: dealStage.id })
      DealHelper.triggerDealStageActionForDeal(deal, dealStage)

      this.props.updateDeal(deal)
    })

    this.setState({ movePopoverActive: false, selectedDealIds: [], actionsActive: false, })
  }


  onMoveDealsPopoverClose() {
    this.setState({
      movePopoverActive: false,
      selectedDealIds: [],
      actionsActive: false
    })
  }

  onBoardFilterSearchChange(e) {
    const searchValue = e.currentTarget.value

    const { boardFilter } = this.state

    this.setState({
      boardFilter: {
        ...boardFilter,
        searchValue: searchValue,
      }
    })
  }

  onActionsCloseClick() {
    this.setState({
      actionsActive: false,
      selectedDealIds: []
    })
  }

  onDealActionMouseOver(action: DealsAction) {
    this.setState({ dragAction: action })
  }

  onDealActionMouseOut() {
    this.setState({ dragAction: null })
  }

  onBoardFilterStatusChange(option) {
    const { boardFilter } = this.state

    this.setState({
      boardFilter: {
        ...boardFilter,
        status: option.value
      }
    })
  }

  getActiveBoardFilterCount() {
    const { boardFilter } = this.state

    let activeFilterCount = 0

    if (boardFilter.searchValue !== '') activeFilterCount += 1
    if (boardFilter.assigneeIds && boardFilter.assigneeIds.length > 0) activeFilterCount += 1
    if (boardFilter.dueState !== null) activeFilterCount += 1

    return activeFilterCount
  }

  onBoardFilterToggle() {
    this.setState({ boardFilterActive: !this.state.boardFilterActive })
  }

  onBoardFilterChange(filter: IDealBoardFilter) {
    this.setState({
      boardFilter: filter
    })
  }

  onBoardFilterClose() {
    this.setState({ boardFilterActive: false })
  }

  onBeforeDragStart() { }

  onDragStart(dragStart: DragStart) {
    if (dragStart.source.droppableId === 'board') return
    this.setState({ actionsActive: true })
  }

  onDragEnd(result: DropResult, provided: ResponderProvided) {
    const { dragAction } = this.state

    if (dragAction) {
      const dealId = result.draggableId

      this.setState({ selectedDealIds: [dealId], dragAction: null }, () => {

        switch (dragAction) {
          case DealsAction.DELETE:
            this.onActionDeleteDrop(dealId)
            break
          case DealsAction.LOST:
            this.onActionLostDrop(dealId)
            break
          case DealsAction.WON:
            this.onActionWonDrop(dealId)
            break
          case DealsAction.MOVE:
            this.onActionMoveDrop(dealId)
            break
        }
      })
      return
    }

    this.setState({ dragAction: null, actionsActive: false })

    if (!result.destination) return;

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Reordering a list
    if (result.type === DealDroppableType.BOARD) {
      this.reorderDealStage(result);
      return;
    }

    // Reordering a card
    this.reorderDeal(result);
  }

  reorderDealStage(result: DropResult) {
    const { source } = result
    const { dealsBoard: { dealStages } } = this.props

    const newDealStagePosition = DroppableHelper.getNewPositionBasedOnDropResult(dealStages, result)

    if (dealStages[source.index]) {
      dealStages[source.index].position = newDealStagePosition

      // Get updated boardlist
      const updatedDealStage: DealStageType = dealStages[source.index]

      // Updated remote boardlist async
      DealStagesController.update(updatedDealStage).catch(console.error)

      // Set local deal stages 
      this.props.updateDealStage(updatedDealStage)
    }
  }

  reorderDeal(result: DropResult) {
    const { source, destination } = result
    const { dealsBoard: { deals, dealStages } } = this.props

    // moving card within same list
    if (source.droppableId === destination.droppableId) {
      const selectedDealStage = dealStages.find(list => list.id === destination.droppableId)

      if (selectedDealStage) {
        // Get deals from the selected list
        const listDeals = DealHelper.getDealsFromStage(selectedDealStage, deals)

        // Get moved dealId
        const movedDeal = listDeals[source.index]
        const movedDealId = movedDeal.id

        // Calculate the new deal position based on the deals inside the list
        const newDealPosition = DroppableHelper.getNewPositionBasedOnDropResult(listDeals, result)

        const dealIndex = deals.findIndex(deal => deal.id === movedDealId)

        if (dealIndex !== -1) {
          deals[dealIndex].position = newDealPosition

          // Get updated boardlist
          const updatedDeal: Deal = deals[dealIndex]

          DealsController.update(updatedDeal).catch(console.error)

          this.props.updateDeal(updatedDeal)
        }
      }
    } else { // moving card between different dealStages
      const currentDealStage = dealStages.find(list => list.id === source.droppableId)
      const movedDealStage = dealStages.find(list => list.id === destination.droppableId)

      if (currentDealStage && movedDealStage) {
        // Get deals from current list
        const currentDealStageDeals = DealHelper.getDealsFromStage(currentDealStage, deals)

        // Get deal that will be moved
        const movedDeal = currentDealStageDeals[source.index]
        const movedDealId = movedDeal.id

        // Get deals from the selected list
        const movedDealStageDeals = DealHelper.getDealsFromStage(movedDealStage, deals)

        // Calculate the new deal position based on the deals inside the list
        const newDealPosition = DroppableHelper.getNewPositionBasedOnDropResult(movedDealStageDeals, result)

        // Find the index of the deal to be moved
        const dealIndex = deals.findIndex(deal => deal.id === movedDealId)

        if (dealIndex !== -1) {
          // Update deal position at given index
          deals[dealIndex] = {
            ...deals[dealIndex],
            position: newDealPosition,
            deal_stage: movedDealStage,
            deal_stage_id: movedDealStage.id,
          }

          // Get updated deal
          const updatedDeal: Deal = deals[dealIndex]

          // Update remote deal
          DealsController.update(updatedDeal).catch(console.error)

          // Update local deal
          this.props.updateDeal(updatedDeal)

          // Trigger deal stage action if applicable
          DealHelper.triggerDealStageActionForDeal(updatedDeal, movedDealStage)
        }
      }
    }
  }

  renderTableLayout() {
    return (
      <DealsTable />
    )
  }

  renderBoardLayout() {
    const { boardFilterActive, boardFilter, movePopoverActive, lostDealsPopoverActive, actionsActive, selectedDealIds } = this.state
    const { dealsBoard: { deals, dealStages }, currentUser, t } = this.props
    const { setting } = currentUser.workspace

    return (
      <DealsContainer>
        <DragDropContext
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <Droppable droppableId='actions' direction='vertical'>
            {(provided, snapshot) => {
              return (
                <DealActionsContainer show={actionsActive} ref={provided.innerRef} {...provided.droppableProps}>
                  <DealActions>
                    <Droppable droppableId='action:delete' direction='vertical'>
                      {(provided, snapshot) => {
                        return (
                          <DealAction
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            onMouseOver={() => this.onDealActionMouseOver(DealsAction.DELETE)}
                            onMouseOut={this.onDealActionMouseOut}
                          >
                            {t('Deals::Delete')}
                          </DealAction>
                        )
                      }}
                    </Droppable>
                    <Popover
                      active={lostDealsPopoverActive}
                      activator={
                        <div style={{ width: '100%' }}>
                          <Droppable droppableId='action:delete' direction='vertical'>
                            {(provided, snapshot) => {
                              return (
                                <DealAction
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  color={Style.color.brandDanger}
                                  onMouseOver={() => this.onDealActionMouseOver(DealsAction.LOST)}
                                  onMouseOut={this.onDealActionMouseOut}
                                >
                                  {t('Deals::Lost')}
                                </DealAction>
                              )
                            }}
                          </Droppable>
                        </div>
                      }
                      onClose={this.onLostDealsPopoverClose}
                      placement='top'
                    >
                      {lostDealsPopoverActive && <LostDealsPopover
                        onSubmit={this.onLostDealsPopoverSubmit}
                        onClose={this.onLostDealsPopoverClose}
                      />}
                    </Popover>

                    <Droppable droppableId='action:won' direction='vertical'>
                      {(provided, snapshot) => {
                        return (
                          <DealAction
                            color={Style.color.brandSuccess}
                            ref={provided.innerRef} {...provided.droppableProps}
                            onMouseOver={() => this.onDealActionMouseOver(DealsAction.WON)}
                            onMouseOut={this.onDealActionMouseOut}
                          >
                            {t('Deals::Won')}
                          </DealAction>
                        )
                      }}
                    </Droppable>

                    <Popover
                      active={movePopoverActive}
                      activator={
                        <div style={{ width: '100%' }}>
                          <Droppable droppableId='action:move' direction='vertical'>
                            {(provided, snapshot) => {
                              return (
                                <DealAction
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  onMouseOver={() => this.onDealActionMouseOver(DealsAction.MOVE)}
                                  onMouseOut={this.onDealActionMouseOut}
                                >
                                  {t('Deals::Move')}
                                </DealAction>
                              )
                            }}
                          </Droppable>
                        </div>
                      }
                      onClose={this.onMoveDealsPopoverClose}
                      placement='top'
                    >
                      <MoveDealsPopover
                        onSubmit={this.onMoveDealsPopoverSubmit}
                        onClose={this.onMoveDealsPopoverClose}
                      />
                    </Popover>
                  </DealActions>
                </DealActionsContainer>
              )
            }}
          </Droppable>

          <Droppable droppableId='board' type={DealDroppableType.BOARD} direction='horizontal'>
            {(provided) => {
              return (
                <DealsWrapper ref={provided.innerRef} {...provided.droppableProps}>
                  {dealStages.map((dealStage, index) => {
                    const stageDeals = DealHelper.getDealsFromStage(
                      dealStage,
                      deals.filter(deal => !selectedDealIds.includes(deal.id)),
                      boardFilter
                    )

                    return (
                      <DealStage
                        key={dealStage.id}
                        index={index}
                        dealStage={dealStage}
                        deals={stageDeals}
                        currency={setting.default_currency}
                        dateFormat={setting.date_format}
                        numberFormat={setting.number_format}
                        onDealClick={this.onDealClick}
                        onAddDealClick={this.onAddDealClick}
                        onChangeDealStageName={this.onChangeDealStageName}
                        onMoveDealsClick={this.onMoveDealsClick}
                        onDeleteDealsClick={this.onDeleteDealsClick}
                        onEditDealStageClick={this.onEditDealStageClick}
                        onDeleteDealStageClick={this.onDeleteDealStageClick}
                      />
                    )
                  })}
                  {provided.placeholder}

                  <AddStageContainer>
                    <AddStageTitle>{t('AddDealStage::Add stage')}</AddStageTitle>
                    <AddStageDescription>{t('AddDealStage::Stages represent the steps in your sales process')}</AddStageDescription>
                    <AddStageAction onClick={this.onAddDealStageClick}>
                      <Icon icon='plus' />
                      {t('AddDealStage::Add stage')}
                    </AddStageAction>
                  </AddStageContainer>
                </DealsWrapper>
              )
            }}
          </Droppable>
        </DragDropContext>

        <DealSidebarFilter
          active={boardFilterActive}
          filter={boardFilter}
          onFilterChange={this.onBoardFilterChange}
          onCloseClick={this.onBoardFilterClose}
        />
      </DealsContainer>
    )
  }

  render() {
    const { t, currentUser } = this.props
    const { activeLayout, didInitialLoad, boardFilter } = this.state

    const filterStatusOptions = [
      { label: t('Deals::Open deals'), value: DealStatusFilter.OPEN },
      { label: t('Deals::Won deals'), value: DealStatusFilter.WON },
      { label: t('Deals::Lost deals'), value: DealStatusFilter.LOST },
      { label: t('Deals::All deals'), value: DealStatusFilter.ALL },
    ]
    const selectedFilterStatusOption = filterStatusOptions.find(status => status.value === boardFilter.status)


    return (
      <>
        <Helmet>
          <title>{t('Deals::{{__appName}} | Deals')}</title>
        </Helmet>

        <TopNavigation
          icon='check-circle'
          title={t('Deals::Deals')}
          action={
            <a key='new-deal' href='javascript://' className='button button-primary page-action' onClick={this.onNewDealClick}>
              <Icon icon='plus' />
              {t('Expenses::New Deal')}
            </a>
          }
        />

        <ScrollToTopOnMount />

        <PageContent fullWidth={true} noAfter={true}>
          <PageHeader
            title={t('Deals::Deals')}
            navigation={
              <PageHeaderNavigation>
                <PageHeaderNavigationLeft>
                  {activeLayout === DealsLayout.BOARD && <>
                    <input
                      type='text'
                      placeholder={t('Deals::Search...')}
                      style={{ maxWidth: 220 }}
                      onChange={this.onBoardFilterSearchChange}
                      value={boardFilter.searchValue}
                    />
                    <div style={{ zIndex: 2 }}>
                      <PowerSelect
                        options={filterStatusOptions}
                        value={selectedFilterStatusOption}
                        onChange={this.onBoardFilterStatusChange}
                        styles={{ container: (styles) => ({ ...styles, minWidth: 150 }) }}
                      />
                    </div>
                    <ButtonFilter
                      activeFilterCount={this.getActiveBoardFilterCount()}
                      onClick={this.onBoardFilterToggle}
                    />
                  </>}
                </PageHeaderNavigationLeft>
                <PageHeaderNavigationRight>
                  <ButtonGroup
                    items={[
                      { element: <span data-tip={t('Deals::Board layout')}><Icon icon='column-layout' /></span>, onClick: () => this.onLayoutChange(DealsLayout.BOARD), active: activeLayout === DealsLayout.BOARD },
                      { element: <span data-tip={t('Deals::Table layout')}><Icon icon='table-layout' /></span>, onClick: () => this.onLayoutChange(DealsLayout.TABLE), active: activeLayout === DealsLayout.TABLE },
                    ]}
                  />
                </PageHeaderNavigationRight>
              </PageHeaderNavigation>}
          />

          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ActionCableConsumer
            channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
            onConnected={this.onActionCableConnected}
            onDisconnected={this.onActionCableDisconnected}
            onReceived={this.onActionCableReceived}
          >
            {activeLayout === DealsLayout.TABLE && this.renderTableLayout()}
            {activeLayout === DealsLayout.BOARD && this.renderBoardLayout()}
          </ActionCableConsumer>}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    dealsBoard
  } = state

  return {
    currentUser: currentUser,
    dealsBoard: dealsBoard
  }
}

const mapDispatchToProps: IDispatchToProps = {
  showDealModal,
  showConfirmModal,
  setInitialDealsState,
  createDeal,
  updateDeal,
  deleteDeal,
  createDealStage,
  updateDealStage,
  deleteDealStage,
  showCalendarEventModal,
  showDealStageModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Deals))